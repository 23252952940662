import type { FileEventType } from '@/components/share/project/type';
import { CollapseKey, FormIds, StepsList } from '@/components/share/project/type';
import { AiAutoTagDefault } from '@/pages/share/useShare';
import { defineStore } from 'pinia';
import { type CoverAutoTag, ModeType } from '~/types/share';
export const useShareProject = defineStore('shareProjectStore', {
  state: () => {
    return {
      // mode: 'sale' as 'sale' | 'free', // 是否付费
      isEdit: false, // 是否编辑
      isAIEditor: null as false | true | null, // 是否为AI编辑器 (从AIMake跳转进入编辑器，初始值为null
      currentStepId: FormIds.BasicInfo as FormIds, // 当前步骤
      // 最终提交的表单
      form: {
        id: null,
        basicForm: {
          forSale: true, // 是否付费
        },
        productionForm: {},
        wrapUpForm: {},
        instructionForm: {},
        productForm: {},
        coverAutoTag: {},
        extra: {
          maxStep: 1,
          medias: [],
        },
      } as any,
      // 请求回来的初始化表单
      requestForm: {} as any,
      // 所有基础映射数据
      baseData: {
        baseProcessTypes: [],
        baseBitmaps: [],
        baseModes: [],
        basePower: [],
        baseMachinesList: [],
        baseLaser: [],
      },
      coverList: [],
      coverAutoTag: AiAutoTagDefault as CoverAutoTag,
      activeKey: Object.values(CollapseKey),
      componentMethod: null as any,
      defaultLicenseId: 0,
      licenseList: [] as any,
      fileEventType: null as FileEventType | null,
      isRequested: false,
      pendingFileEvent: null as FileEventType | null,
    };
  },
  getters: {
    projectFileExtra: (state) => {
      return (
        state.form.basicForm?.attachFiles
          ?.filter((file: any) => isXcsFile(file.name) && !file.id)
          .map((item: any) => item.extra)
          ?.filter((item: any) => !!item) ?? []
      );
    },
    allFileExtra: (state) => {
      return (
        state.form.basicForm?.attachFiles
          ?.filter((file: any) => isXcsFile(file.name))
          .map((item: any) => item.extra)
          ?.filter((item: any) => !!item) ?? []
      );
    },
    existXcsFile: (state) => {
      return state.form.basicForm?.attachFiles?.some((item: any) => item?.name?.indexOf('xcs') > -1);
    },
    // 当前菜单展示的步骤列表
    currentStepList: (state) => {
      const arr = JSON.parse(JSON.stringify(StepsList));
      if (!state.form.instructionForm.hasInstruction) {
        const index = arr.findIndex((item: any) => item.id === FormIds.Instruction);
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }
      if (!state.form.productionForm.hasProduction) {
        const index = arr.findIndex((item: any) => item.id === FormIds.ProductionInfo);
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }
      return arr;
    },
    mode: (state) => {
      return state.form.basicForm?.forSale ? ModeType.Sale : ModeType.Free;
    },
  },
  actions: {
    setIsEdit(isEdit: boolean) {
      this.isEdit = isEdit;
    },
    changeStep(currentStepId: FormIds) {
      this.currentStepId = currentStepId;
    },
    // 设置表单数据
    setForm(key: string, val: any) {
      this.form[key] = val;
    },
    setRequestForm(form: any) {
      this.form.id = form.id;
      const paraSettings = form.paraSettings ? form.paraSettings : [];
      this.requestForm = { ...form, paraSettings };
    },
    setCoverAutoTag(coverAutoTag: CoverAutoTag) {
      this.coverAutoTag = coverAutoTag;
      this.form.coverAutoTag = {
        title: coverAutoTag.title,
        podTags: coverAutoTag.podTags,
        tags: coverAutoTag.tags,
      };
    },
    setAutoFileForm(form: any) {
      this.requestForm = { ...this.requestForm, ...form };
    },
    setDefaultLicense(id: number) {
      this.defaultLicenseId = id;
    },
    setLicenseList(any: any) {
      this.licenseList = any;
    },
    initActivityKey() {
      this.activeKey = Object.values(CollapseKey);
    },
    changeInstructionForm(obj: any) {
      this.form.instructionForm = {
        ...this.form.instructionForm,
        ...obj,
      };
    },
    setComponentMethod(method: any) {
      this.componentMethod = method;
    },
    callComponentMethod(payload: any) {
      if (this.componentMethod) {
        this.componentMethod(payload);
      }
    },
    triggerFileEvent(event: FileEventType | null) {
      if (this.isRequested) {
        this.fileEventType = event;
      } else {
        this.pendingFileEvent = event; // 自动带入xcs可能会在isRequested状态更改前触发，先缓存状态
      }
    },
    updateRequestStatus(status: boolean) {
      this.isRequested = status;
      if (this.isRequested && this.pendingFileEvent) {
        this.triggerFileEvent(this.pendingFileEvent);
        this.pendingFileEvent = null;
      }
    },
    updateCoverList(list: any) {
      this.coverList = list || [];
    },
  },
});
